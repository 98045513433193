import React from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from '@core/scripts/helper/jsh-module';
import HamburgerMenu from '../../components/HamburgerMenu.jsx';
//IMPORTANT dont remove, necessary because PaLi breakpoint detection pub sub needs to be initialized
import Breakpoint from '@bonprix/pattern-library/components/breakpoint';

const {moduleScope} = JshModule('hamburger-menu');

createRoot(moduleScope).render(<HamburgerMenu />);
